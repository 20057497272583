body, html {
    height: 100%;
    margin: 0;
    scroll-behavior: auto !important;
}

/** loading page **/
.center-loading-msg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

/** typography **/
p {
    color: #323232;
    font-size: 15px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Lato", sans-serif;
    color: #323232;
}

a:hover {
    text-decoration: none;
}

.col-centered {
    float: none;
    margin: 0 auto;
}

.padding-none {
    padding: 0px;
}

/** global button **/
.btn {
    border-radius: 0px;
    background: transparent;
    border: 4px solid #28a2c9;
    transition: all 0.2s ease;
    color: #ffffff;
    font-weight: 700;
}

.btn:hover {
    border-radius: 0px;
    border: 4px solid #2184a4;
    color: #eeeeee;
    background: transparent;
}

/** well global **/
.well {
    border-radius: 0px;
}

/** hover content replacement */
.content {
    transition: all 0.3s linear;
}

.hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.hero-text p {
    color: #ffffff;
}

.hero-text h1 {
    color: white;
    font-size: 45px;
}

#main {
    transition: margin-left .5s;
}

.section-indent {
  padding-left: 15px;
}

@media (min-width: 992px) {
  .section-indent {
    padding-left: 100px;
  }
}

@media (min-width: 1200px) {
  .section-indent {
    padding-left: 166px;
  }
}
